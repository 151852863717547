import clsx from 'clsx';
import s from './Header.module.scss';
import sg from '../../styles/global.module.scss';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import { addOutline, mailOutline } from 'ionicons/icons';
import ButtonMicroWrapper from '../Buttons/ButtonMicroWrapper/ButtonMicroWrapper';
import LocationPicker from '../LocationPicker/LocationPicker';
import { logoWhatsapp } from 'ionicons/icons';
import { useLocation } from 'react-router';

export interface HeaderProps {
  title?: string;
  backButtonText?: string;
  hideBackButton?: boolean;
  showLocationPicker?: boolean;
  routerLink__message?: string;
  routerLink__add?: string;
}

const Header: React.FC<HeaderProps> = props => {
  const location = useLocation();
  const isHelpButtonVisible =
    location.pathname.startsWith('/u/catalog') &&
    location.pathname !== '/u/catalog/assistance/request';

  return (
    <IonHeader>
      <IonToolbar mode="ios">
        {!props.hideBackButton && (
          <IonButtons slot="start">
            <IonBackButton
              text={props.backButtonText}
              defaultHref="/default"
              className={clsx(
                s.IonBackButton,
                sg.Hover_Animation_Transparency,
                'disableIosSafariSwipeBlocker'
              )}
            />
          </IonButtons>
        )}

        {props.showLocationPicker && <LocationPicker />}

        <IonTitle>
          <h1 className={clsx(s.IonTitle__h1)}>{props.title}</h1>
        </IonTitle>

        <IonButtons slot="end">
          {/* Each of below components is designed for some particular page */}
          {props.routerLink__message && (
            <ButtonMicroWrapper routerLink={props.routerLink__message}>
              <IonIcon
                icon={mailOutline}
                className={clsx(s.IonIcon__message)}
              />
            </ButtonMicroWrapper>
          )}

          {props.routerLink__add && (
            <ButtonMicroWrapper routerLink={props.routerLink__add}>
              <IonIcon icon={addOutline} className={clsx(s.IonIcon__add)} />
            </ButtonMicroWrapper>
          )}

          {isHelpButtonVisible && (
            <IonButton
              mode="md"
              fill="solid"
              color="success"
              shape="round"
              routerLink={'/u/catalog/assistance/request'}
              className={clsx(
                s.IonButton__help,
                isPlatform('desktop') ? s.IonButton__help__desktop : undefined,
                'disableIosSafariSwipeBlocker'
              )}
            >
              <IonIcon
                icon={logoWhatsapp}
                style={{
                  fontSize: '24px',
                  marginRight: '5px',
                }}
              />
              <p>Fast Help</p>
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
