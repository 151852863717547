import React from 'react';
import s from './Benefits.module.scss';
import { useLandingPageContext } from '../../../context';
import { useMedia } from '../../../../../hooks/hook.media';
import clsx from 'clsx';

import sg from '../../../../../styles/global.module.scss';
import { TextLink } from '../../../../../components/TextLink/TextLink';
import { TextUsButton } from '../Form/Subforms/TextUsButton';
import { ActionButton } from '../../ActionButton/ActionButton';
import { WHATS_APP_PHONE_NUMBER } from '../../../../../utils/phoneNumber';

export const Benefits = () => {
  // TODO: Use breakpoints
  const isDesktop = useMedia(['(min-width: 680px)'], [true, false], false);
  // console.log({ isDesktop });

  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  const content = `Hello, can you organize the event for me?`;
  const link = `https://wa.me/${WHATS_APP_PHONE_NUMBER}?text=${encodeURI(
    content
  )}`;

  return (
    <section className={s.section}>
      <div className={s.container}>
        <h3 data-animation className={s.section__title}>
          Use <span>Eventmaker</span> to find and book...
        </h3>
        <div className={s.benefits}>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>1. Event services</h4>

            <p className={s.benefit__body}>
              Venues, sound equipment, entertainment, and all you need for event
              <div
                className={clsx(sg.Stack_Vertical, sg.Gap_20)}
                style={{ marginTop: '30px' }}
              >
                <TextLink title="▸ About vendors and their services" />
                <TextLink title="▸ How our platform guarantee best prices?" />
                <ActionButton
                  style={{ marginTop: '20px' }}
                  text="Explore Catalog"
                />
              </div>
            </p>
          </div>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>2. Event agencies</h4>

            <p className={s.benefit__body}>
              {/* Find experts to plan your event: whether it's wedding, business
              conference or birthday party. */}
              They will plan wedding, business conference or birthday party for
              you
              <div
                className={clsx(sg.Stack_Vertical, sg.Gap_20)}
                style={{ marginTop: '30px' }}
              >
                <TextLink title="▸ About event agencies' prices" />
                <TextLink title="▸ How to choose the right event agency?" />
                <ActionButton
                  style={{ marginTop: '20px' }}
                  text="Find Event Agency"
                />
              </div>
            </p>
          </div>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>3. Event concierge</h4>
            <p className={s.benefit__body}>
              Share your vision, and we'll bring your event to life in the best
              possible way
              <div
                className={clsx(sg.Stack_Vertical, sg.Gap_20)}
                style={{ marginTop: '30px' }}
              >
                <TextLink title="▸ Reviews" />
                <TextLink title="▸ How do we organize your events?" />
                <TextUsButton
                  style={{ marginTop: '20px' }}
                  onClick={() => window.open(link)}
                />
              </div>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
