import edit from './assets/icons/edit.svg';
import swap from './assets/icons/swap.svg';
import like from './assets/icons/like.svg';
import logo from './assets/icons/logo.svg';
import tabInactive from './assets/tab/inactive.svg';
import tabActive from './assets/tab/active.svg';

// Event
import birthday from './assets/search/event/birthday.svg';
import party from './assets/search/event/party.svg';
import wedding from './assets/search/event/wedding.svg';
import festival from './assets/search/event/festival.svg';
import new_year from './assets/search/event/new_year.svg';
import child_party from './assets/search/event/child_party.svg';
import photo_video from './assets/search/event/photo_video.svg';
import concert from './assets/search/event/concert.svg';
import business_conf from './assets/search/event/business_conf.svg';
import exhibition from './assets/search/event/exhibition.svg';
import yoga_or_dance from './assets/search/event/yoga_or_dance.svg';
import music_session from './assets/search/event/music_session.svg';
import sport from './assets/search/event/sport.svg';
import other from './assets/search/event/other.svg';

// Service
import ProductVenue from './assets/search/service/venue.svg';
import ProductDecoration from './assets/search/service/decoration.svg';
import ProductSoundEquipment from './assets/search/service/sound_equipment.svg';
import ProductLightEquipment from './assets/search/service/light_equipment.svg';
import ProductPerson from './assets/search/service/person.svg';
import ProductCatering from './assets/search/service/catering.svg';
import ProductEntertainment from './assets/search/service/entertainment.svg';
import ProductAgency from './assets/search/service/agency.svg';
import ProductStage from './assets/search/service/stage.svg';
import ProductBeauty from './assets/search/service/beauty.svg';
import ProductTransportation from './assets/search/service/transportation.svg';
import ProductPhotoEquipment from './assets/search/service/photo_equipment.svg';

export const Icons = {
  swap,
  like,
  logo,
  edit,
  tabInactive,
  tabActive,
};

export const SearchIcons = {
  // Event
  birthday,
  party,
  wedding,
  festival,
  new_year,
  child_party,
  photo_video,
  concert,
  business_conf,
  exhibition,
  yoga_or_dance,
  music_session,
  sport,
  other,

  // Service
  ProductVenue,
  ProductDecoration,
  ProductSoundEquipment,
  ProductLightEquipment,
  ProductPerson,
  ProductCatering,
  ProductEntertainment,
  ProductAgency,
  ProductStage,
  ProductBeauty,
  ProductTransportation,
  ProductPhotoEquipment,
} as any;
