import React, { useEffect, useRef, useState } from 'react';
import Page from '../../../components/Page/Page';
import {
  BaseQueryParamsType,
  ProductCategoriesQueryParamsType,
  SearchArgsType,
} from '../../../redux/_types';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { getSearchParameters } from '../../../utils/queryParams';
import { convertQueryParamsToSearchArgs } from '../../../redux/_helpers';
import SearchContent from '../../../components/SearchContent/SearchContent';
import Spinner from '../../../components/Spinner/Spinner';
import { choicesSlice } from '../../../redux/slices/choices/slice';
import { AnalyticEventsEnum, trackEvent } from '../../../Analytics';

const UserCatalogPage: React.FC = () => {
  const dispatch = useTypedDispatch();

  const eventCategories = useTypedSelector(s => s.choices.eventCategorySet);
  const productCategories = useTypedSelector(s => s.choices.productCategorySet);
  const cities = useTypedSelector(s => s.choices.citySet);
  const selectedCity = useTypedSelector(s => s.choices.selectedCity);

  const [initialSearchArgs, setInitialSearchArgs] = useState<SearchArgsType>();

  const queryParamsNotProcessedYet = useRef(true);

  useEffect(() => {
    if (
      !eventCategories.length ||
      !productCategories.length ||
      !cities.length
    ) {
      return;
    }

    const queryParams: BaseQueryParamsType & ProductCategoriesQueryParamsType =
      getSearchParameters();

    const providedSearchArgs = convertQueryParamsToSearchArgs(
      queryParams,
      eventCategories,
      productCategories,
      cities,
      selectedCity || cities[0],
      queryParamsNotProcessedYet.current
    );

    setInitialSearchArgs(providedSearchArgs);
    queryParamsNotProcessedYet.current = false;
  }, [eventCategories, productCategories, cities, dispatch, selectedCity]);

  useEffect(() => {
    if (initialSearchArgs?.city)
      dispatch(choicesSlice.actions.setSelectedCity(initialSearchArgs.city));
  }, [initialSearchArgs?.city, dispatch]);

  useEffect(() => {
    trackEvent(AnalyticEventsEnum.VIEW_CONTENT);
    trackEvent(AnalyticEventsEnum.VIEW_CATALOG_PAGE);
  }, []);

  return (
    <Page
      disableDefaultIonContent
      pageLimitDisabled
      headerProps={{
        hideBackButton: true,
        showLocationPicker: true,
      }}
    >
      {!initialSearchArgs ? (
        <Spinner hasCountdown />
      ) : (
        <SearchContent initialSearchArgs={initialSearchArgs} />
      )}
    </Page>
  );
};

export default React.memo(UserCatalogPage);
