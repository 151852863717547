import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import Page from '../../../components/Page/Page';
import { AnalyticEventsEnum, trackEvent } from '../../../Analytics';
import { CustomerHelpForm } from '../../landing/Components/Sections/Form/CustomerHelpForm';
import s from '../../landing/Components/Sections/Form/Form.module.scss';

type Props = {};

const UserAssistancePage: React.FC<Props> = () => {
  useEffect(() => {
    trackEvent(AnalyticEventsEnum.VIEW_ASSISTANCE_PAGE);
  }, []);

  const location = useLocation();

  return (
    <Page headerProps={{ backButtonText: 'To Catalog' }}>
      <div className={s.inapp_block}>
        <h3 className={clsx(s.sectionHeader)} data-animation>
          Get your FREE consultation today!
        </h3>

        <p className={clsx(s.sectionSubheader)}>
          We can plan your event in Bali or find specific event services based
          on your needs.
          <br />
          <br />
          Contact us in Whats App:
        </p>
      </div>

      {/* Fix bug when page is rendered but not visible for user, and user submit another form */}
      {location.pathname === '/u/catalog/assistance/request' && (
        <CustomerHelpForm applyCustomStyles={false} />
      )}

      <div style={{ height: '70px' }} />
    </Page>
  );
};

export default UserAssistancePage;
