import { createContext, useContext, useState } from 'react';
import { ContactInfoModal } from './ContactInfoModal';
import { ProductCategory } from '../../models/product';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { authActionCreators } from '../../redux/slices/auth/actionCreators';
import { globalActionCreators } from '../../redux/slices/global/actionCreator';
import { ToastPriorityEnum } from '../../utils/enums';
import { IS_INSTAGRAM } from '../../utils/browser';
import { ExclusiveAccessModal } from './ExclusiveAccessModal';

export type ContactInfoType = {
  isExclusiveAccessModal?: boolean;
  businessId: string;
  product?: {
    id: string;
    title: string;
    category: ProductCategory;
  };
};

/* Setup */
const useContactInfoSetup = () => {
  const dispatch = useTypedDispatch();
  const user = useTypedSelector(s => s.auth.user);

  const [info, _setInfo] = useState<ContactInfoType>();

  const setInfo = (info: ContactInfoType | undefined) => {
    // if (user || IS_INSTAGRAM) {
    // LETS DO IT Forcely for everyone
    if (true) {
      _setInfo(info);
    } else {
      dispatch(authActionCreators.changeAuthPhase('signUp_Page'));

      setTimeout(
        () =>
          dispatch(
            globalActionCreators.setToastObject({
              message: 'Please login to be able contact the vendor.',
              color: 'warning',
              priority: ToastPriorityEnum.HIGH,
              duration: 5000,
            })
          ),
        500
      );
    }
  };

  return { info, setInfo };
};

/* Context */
export type ContactInfoContextType =
  | ReturnType<typeof useContactInfoSetup>
  | undefined;

const ContactInfoContext = createContext<ContactInfoContextType>(undefined);

/* Provider */
export const ContactInfoContextProvider: React.FC<any> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const contactInfoContextData = useContactInfoSetup();

  return (
    <ContactInfoContext.Provider value={contactInfoContextData}>
      <ContactInfoModal contactInfoContextData={contactInfoContextData} />
      <ExclusiveAccessModal contactInfoContextData={contactInfoContextData} />
      {children}
    </ContactInfoContext.Provider>
  );
};

/* Usage */
export const useContactInfoContext = () => {
  const context = useContext(ContactInfoContext);

  if (!context) {
    throw new Error('Context is not provided');
  }

  return context;
};
