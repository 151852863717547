import { Color } from '@ionic/core';
import { IonText } from '@ionic/react';

type Props = {
  children?: React.ReactNode;
  color?: string;
  fontWeight?: number;
  fontSize?: string;
};

export const Text = ({ children, color, fontWeight, fontSize }: Props) => {
  return (
    // <IonText color={color}>
    <p style={{ fontWeight, fontSize, color }}>{children}</p>
    // </IonText>
  );
};
