import clsx from 'clsx';

import sg from '../../styles/global.module.scss';
import s from './ExclusiveAccess.module.scss';
import { Text } from '../atoms/Text/Text';
import Button from '../Buttons/Button/Button';
import {
  ContactInfoType,
  useContactInfoContext,
} from '../ContactInfoModal/context';

type Props = {
  contactInfoContextData: ContactInfoType;
};

export const ExclusiveAccess = ({ contactInfoContextData }: Props) => {
  const { setInfo } = useContactInfoContext();
  const openContactModal = () => setInfo(contactInfoContextData);

  return (
    <div className={clsx(s.Container, sg.Stack_Vertical, sg.Gap_10)}>
      <Text fontWeight={500} fontSize="20px">
        Exclusive access 🎉
      </Text>
      <Text fontSize="16px" color="var(--color-main-high)">
        As our partner, you can contact vendors directly. Please mention that
        you found them through EventMaker.
      </Text>
      <Button
        text="Contact Vendor"
        color="primary"
        fill="solid"
        onClick={openContactModal}
      />
    </div>
  );
};
