import React, { useEffect, useReducer } from 'react';
import InputSelect from '../Inputs/InputSelect/InputSelect';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SearchFormValues, SortByEnum } from '../SearchBlock/interface';
import { validationErrors, validators } from '../../utils/validators';
import Form from '../Form/Form';
import { useSumbitFormCallback } from '../Form/submit.hook';
import Input from '../Inputs/Input/Input';
import AllCategories from '../ProductCategories/AllCategories';
import InputSelectOption from '../Inputs/InputSelect/InputSelectOption';
import {
  CheckboxAddress,
  checkedCategoriesReducerWrapper,
} from '../ProductCategories/dispatcher';
import { ProductCategory } from '../../models/product';
import { EventCategory } from '../../models/event';

type FormType = SearchFormValues & {
  selectedProductCategory?: ProductCategory;
  selectedEventCategory?: EventCategory;
};

type Props = {
  savedFormValues: SearchFormValues;
  submitCallback: (values: SearchFormValues) => Promise<void>;
  selectedProductCategory: ProductCategory | undefined;
  selectedEventCategory: EventCategory | undefined;
};

const validationSchema: Yup.SchemaOf<Partial<FormType>> = Yup.object().shape({
  cityId: Yup.string().nullable(),
  sortBy: Yup.mixed<SortByEnum>().oneOf(Object.values(SortByEnum)),
  peopleCapacity: validators.nullableNumber,
  eventDuration: validators.nullableNumber.when('sortBy', {
    is: SortByEnum.BY_PRICE,
    then: schema =>
      schema
        .min(1, validationErrors.HOURS)
        .max(24, validationErrors.HOURS)
        .required('Please provide approximate duration of your event'),
  }),
  productCategories: Yup.object().test(
    'is-at-least-one-subcategory-selected',
    'Select at least one subcategory',
    (_productCategories, { parent }) => {
      const selectedProductCategory = parent.selectedProductCategory as
        | ProductCategory
        | undefined;

      const productCategories = selectedProductCategory
        ? {
            [selectedProductCategory.id]:
              _productCategories[selectedProductCategory.id],
          }
        : _productCategories;

      let atLeastOneSubcategorySelected = false;
      for (const checkedSubcategories of Object.values(productCategories)) {
        if (checkedSubcategories === true) {
          atLeastOneSubcategorySelected = true;
          break;
        } else if (typeof checkedSubcategories !== 'boolean') {
          for (const checkedSubcategory of Object.values(
            checkedSubcategories
          )) {
            if (checkedSubcategory) {
              atLeastOneSubcategorySelected = true;
              break;
            }
          }
        }
      }

      return atLeastOneSubcategorySelected;
    }
  ),
  selectedProductCategory: Yup.object().shape({}).nullable(),
  selectedEventCategory: Yup.object().shape({}).nullable(),
});

const SearchForm: React.FC<Props> = ({
  submitCallback,
  savedFormValues,
  selectedProductCategory,
  selectedEventCategory,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted },
    reset,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...savedFormValues,
      selectedProductCategory,
      selectedEventCategory,
    } as Partial<FormType>,
  });

  const { onSubmit, updateProductCategoriesInForm } =
    useSumbitFormCallback<FormType>({
      submitCallback,
      handleSubmit,
      reset,
      trigger,
      isSubmitted,
      setValue,
    });

  const [checkedCategories, checkedCategoriesDispatcher] = useReducer(
    checkedCategoriesReducerWrapper(
      updateProductCategoriesInForm,
      savedFormValues.productCategories || {}
    ),
    savedFormValues.productCategories || {}
  );

  useEffect(() => {
    /* modal is closed */
    if (!selectedEventCategory && !selectedProductCategory) {
      reset(savedFormValues);
      checkedCategoriesDispatcher({ action: 'reset' });
    }

    const isEmptyParentCategory =
      !!selectedProductCategory && !selectedProductCategory.subcategories;

    if (isEmptyParentCategory) {
      const checkboxAddress: CheckboxAddress = {
        categoryId: selectedProductCategory.id,
      };

      checkedCategoriesDispatcher({
        action: 'changeCheckedToTrue',
        checkboxAddress,
      });
    }
  }, [
    savedFormValues,
    selectedEventCategory,
    selectedProductCategory,
    checkedCategoriesDispatcher,
    reset,
  ]);

  const sortBy = watch('sortBy');

  /* prevent flickering */
  if (!selectedEventCategory && !selectedProductCategory) return null;

  return (
    <Form
      onSubmit={onSubmit}
      stopScrollbarPropagation
      showSubmitButton
      showSubmitButtonAtBottom
      showSubmitButtonText="Search"
    >
      {/* ------- Sort By ------ */}
      <InputSelect
        label="Your priority"
        _name="sortBy"
        _register={register}
        _errorText={errors.sortBy?.message}
      >
        {Object.values(SortByEnum).map(sortBy => (
          <InputSelectOption
            key={sortBy}
            value={sortBy}
            textPreview={
              sortBy === SortByEnum.DEFAULT
                ? 'Optimal choice'
                : sortBy === SortByEnum.BY_PRICE
                ? 'Lowest price'
                : sortBy === SortByEnum.BY_RATING
                ? 'Highest rating'
                : sortBy === SortByEnum.BY_NEW
                ? 'New offerings'
                : '- Error -'
            }
          />
        ))}
      </InputSelect>

      {/* ------- Event duration ------ */}
      {sortBy === SortByEnum.BY_PRICE && (
        <Input
          type="number"
          inputmode="numeric"
          label={
            selectedProductCategory
              ? 'Rent duration in hours'
              : 'Event duration (hours)'
          }
          _name="eventDuration"
          _register={register}
          _errorText={errors.eventDuration?.message}
        />
      )}

      {/* ------- People Capacity ------ */}
      {selectedEventCategory && (
        <Input
          type="number"
          inputmode="numeric"
          label="Number of guests (optional)"
          // placeholder="Optional"
          _name="peopleCapacity"
          _register={register}
          _errorText={errors.peopleCapacity?.message}
        />
      )}

      {/* ------- Categories ------ */}
      <AllCategories
        label={'What are you looking for?'}
        selectedProductCategory={selectedProductCategory}
        checkedCategories={checkedCategories}
        checkedCategoriesDispatcher={checkedCategoriesDispatcher}
        _name="productCategories"
        _register={register}
        _errorText={String(errors.productCategories?.message || '')}
        __updateProductCategoriesInForm={updateProductCategoriesInForm}
      />
    </Form>
  );
};

export default SearchForm;
