import { IonIcon } from '@ionic/react';
import { logoWhatsapp } from 'ionicons/icons';
import { WHATS_APP_PHONE_NUMBER } from '../../../../../../utils/phoneNumber';
import s from '../Form.module.scss';

type Props = {
  applyCustomStyles?: boolean;
  alignToCenter?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  vendorNumber?: string;
};

export const TextUsButton: React.FC<Props> = ({
  applyCustomStyles,
  alignToCenter,
  onClick,
  style,
  vendorNumber,
}) => {
  return (
    <div>
      <button
        className={s.whatsAppButton}
        type="submit"
        onClick={onClick}
        style={style}
      >
        <IonIcon icon={logoWhatsapp} style={{ fontSize: '24px' }} />
        {vendorNumber ? 'Text vendor *' : 'Text us *'}
      </button>

      <p
        style={{
          textAlign: alignToCenter ? 'center' : undefined,
          color: applyCustomStyles ? 'black' : 'rgba(255, 255, 255, 0.7)',
          marginTop: '15px',
          fontSize: '12px',
          ...(alignToCenter
            ? {
                marginLeft: '15px',
                marginRight: '15px',
              }
            : { marginLeft: '3px' }),
        }}
      >
        {vendorNumber
          ? `* Message vendor in Whats App: ${vendorNumber}`
          : `* Message us in Whats App: ${WHATS_APP_PHONE_NUMBER}`}
      </p>
    </div>
  );
};
